<template>
  <div>
    <v-card flat class="grey lighten-5">
        <v-container class="grey lighten-5 fill-height">
            <v-card :shaped="true" :raised="true" width="100%" style="margin-bottom: 25px">
                <!-- <v-card :shaped="true" > -->
                <div class="align-center" style="background: #FFFFFF; box-shadow: 0px 3px 12px #0000000F; margin-bottom: 16px; width: 100%; height: 80px;">
                    <v-row>
                        <v-col :md="1" class="ml-4">
                            <v-avatar tile height="60">
                                <v-icon size="55">$vuetify.icons.cashback</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col :md="7" class="pt-6">
                            <div class="title" style="color: #BC1279; margin-left: -30px">Djemari Salon and Spa</div>
                        </v-col>
                        <v-col class="text-right mr-4">
                            <v-btn rounded depressed class="text-capitalize" color="#00D79E" style="color: #FFFFFF" @click="editCashback">{{ lang.t('$vuetify.edit') }} {{ lang.t('$vuetify.cashback') }}</v-btn>
                            <v-btn color="#FDF1EF" fab small class="ma-2 white--text" style="border: 0.1px solid #E74C3C;">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <!-- </v-card> -->
                    <v-row class="mt-4 ml-6">
                        <v-col :md="4" class="mt-3">
                            <v-img
                                height="304"
                                width="376"
                                src="@/assets/adult-barber.png"
                            ></v-img>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col :md="4">
                                    <div class="text-center pt-2" style="width: 168px; height: 80px;border: 2px solid #BC1279;border-radius: 8px;">
                                        <div class="body-1">{{ lang.t('$vuetify.cashback') }}</div>
                                        <div class="display-1 font-weight-bold" style="color: #BC1279">10%</div>
                                    </div>
                                </v-col>
                                <v-col :md="3">
                                    <div class="mt-3">
                                        <div class="body-1" style="color: #BEC2C4">{{ lang.t('$vuetify.cashback_type') }}</div>
                                        <div class="body-1 font-weight-bold">Discount</div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top: -15px">
                                <v-col :md="10">
                                    <v-row>
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.set_for_first_order') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">Yes</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.min_purchase') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">Rp50.000</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.cashback_limit') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">300</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.max_usage_customer') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">3 times</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.date_period') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">22 Aug 2020 - 22 Jan 2021</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                    <v-row style="margin-top: -5px">
                                        <v-col :md="5">
                                            <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.day') }}</span>
                                        </v-col>
                                        <v-col>
                                            <span class="subtitle-1">Every Day</span>
                                        </v-col>
                                    </v-row>
                                    <hr style="border-top: 1px solid #BEC2C4; margin-top: -10px">
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                <p class="font-weight-bold title ml-6 mt-8" > {{ lang.t('$vuetify.location') }} </p>
                <v-row align="center" class="mx-4 mb-4" style="width: 120%">
                    <v-col v-for="i in 8" :key="i" class="mr-2" :md="5">
                        <div style="width: 500px; height: 64px; border: 1px solid #BEF4E6; border-radius: 10px;">
                            <v-row>
                                <v-col :md="7" class="ml-4 my-2">Anissa Salon Spa</v-col>
                                <v-col :md="4" class="text-center py-4" style="height: 62px; margin-left: 15px; background: #F8F8F8; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                                <span class="subtitle-1">Batam</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
            <div>
                <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.created_by') }}</span>
                <div>
                    <v-avatar size="28" class="mr-3">
                        <v-img src="@/assets/Screenshot(4).png"></v-img>
                    </v-avatar>
                    <span class="subtitle-1 font-weight-bold">Andi Suseno</span>
                    <span class="subtitle-1" style="color: #BEC2C4"> at </span>
                    <span class="subtitle-1 font-weight-bold">15 Feb 2020, 13:30</span>
                </div>
            </div>
            <v-dialog
              v-model="dialog"
              max-width="664">
              <v-card class="pb-5">
                <v-toolbar color="elevation-0">
                  <v-toolbar-title >
                    <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.edit') }} {{ lang.t('$vuetify.cashback') }}</span><br>
                    <div class="body-2 ml-4" style="color: #BEC2C4">{{ lang.t('$vuetify.cashback') }} {{ lang.t('$vuetify.detail') }}</div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="mx-6 mt-6">
                    <div class="ml-2">
                    <v-card raised>
                      <div class="d-flex flex-no-wrap justify-space-between ml-4">
                        <div>
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title class="body-1">Djemari Salon and Spa Multi Genus Alabama</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                        <v-btn text class="mt-4 text-capitalize" color="#1A73E8">{{ lang.t('$vuetify.change') }}</v-btn>
                      </div>
                    </v-card>
                      <v-row>
                        <v-col :md="6" >
                            <div class="justify-space-between">
                              <div class="d-flex flex-no-wrap justify-space-between" style="margin-bottom: 8px">
                                  <span class="mt-4">{{ lang.t('$vuetify.cashback') }}</span>
                                </div>
                              <v-text-field
                                v-model="amount"
                                outlined
                                dense
                                suffix="%"
                              ></v-text-field>
                                <div class="d-flex flex-no-wrap justify-space-between" style="margin-bottom: -20px">
                                  <span class="mt-4">{{ lang.t('$vuetify.minimum_purchase') }}</span>
                                  <v-switch
                                    v-model="switch1"
                                  ></v-switch>
                                </div>
                              <v-text-field
                                v-model="amount"
                                outlined
                                dense
                              ></v-text-field>
                              <div class="d-flex flex-no-wrap justify-space-between" style="margin-bottom: -20px">
                                  <span class="mt-4">{{ lang.t('$vuetify.cashback_limit') }}</span>
                                  <v-switch
                                    v-model="switch1"
                                  ></v-switch>
                                </div>
                              <v-text-field
                                v-model="amount"
                                outlined
                                dense
                              ></v-text-field>
                              <div class="d-flex flex-no-wrap justify-space-between" style="margin-bottom: -20px">
                                  <span class="mt-4">{{ lang.t('$vuetify.max_usage_customer') }}</span>
                                  <v-switch
                                    v-model="switch1"
                                  ></v-switch>
                                </div>
                              <v-text-field
                                v-model="amount"
                                outlined
                                dense
                              ></v-text-field>
                              <div class="d-flex flex-no-wrap justify-space-between">
                                <span class="mt-4">{{ lang.t('$vuetify.platform_applied') }}</span>
                              </div>
                              <v-autocomplete
                                v-model="values"
                                :items="['App (Android & iOS)', 'Web']"
                                outlined
                                dense
                                multiple
                              ></v-autocomplete>
                            </div>
                        </v-col>
                        <v-col :md="6" >
                            <div class="justify-space-between">
                              <div class="d-flex flex-no-wrap justify-space-between" >
                                  <span class="mt-4 mb-2">{{ lang.t('$vuetify.expiry_period') }}</span>
                                </div>
                              <v-autocomplete
                                v-model="selectCity"
                                :items="dropdown_gender"
                                outlined
                                dense
                                label="1 Month"
                                ></v-autocomplete>
                              <div class="d-flex flex-no-wrap justify-space-between">
                                  <span class="mt-4 mb-2">Day <v-icon left>$vuetify.icons.info</v-icon></span>
                                </div>
                              <v-autocomplete
                                v-model="selectCity"
                                :items="dropdown_gender"
                                outlined
                                dense
                                label="Everyday"
                                ></v-autocomplete>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                  <span class="mt-4 mb-2">{{ lang.t('$vuetify.set_for_newmember') }} <v-icon left>$vuetify.icons.info</v-icon></span>
                                </div>
                              <v-autocomplete
                                v-model="selectCity"
                                :items="['Yes', 'No']"
                                outlined
                                dense
                                ></v-autocomplete>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                  <span class="mt-4 mb-2">{{ lang.t('$vuetify.location_applied') }} <v-icon left>$vuetify.icons.info</v-icon></span>
                                </div>
                              <v-autocomplete
                                v-model="selectCity"
                                :items="dropdown_gender"
                                outlined
                                dense
                                label="All Location"
                                ></v-autocomplete>
                            </div>
                        </v-col>
                      </v-row>
                      <div class="text-center">
                        <v-btn width="552px" rounded color="#00D79E" style="color: #FFFFFF">{{ lang.t('$vuetify.save') }}</v-btn>
                      </div>
                    </div>
                </div>
              </v-card>
            </v-dialog>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

// import LocationDetail from './_location-detail'
// import LocationStaff from './_location-staff'
// import LocationSales from './_location-sales'
export default {
  name: 'ReportMerchant',
  data () {
    return {
      search: '',
      tab: null,
      tab2: null,
      address: 'Jalan Akasia, Jl. Seturan Raya No.1A, Kledokan, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281',
      items: [
        'Merchant List', 'Activity', 'Transaction', 'Acquisition'
      ],
      itemsTab: [
        'Profile', 'Location', 'Staff', 'Sales'
      ],
      alignments: [
        'center'
      ],
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        }
      ],
      headers: [
        {
          text: 'Merchant Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Total Staff',
          align: 'center',
          value: 'total_staff'
        },
        {
          text: 'Platform',
          align: 'left',
          value: 'platform'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Time Joined',
          align: 'right',
          value: 'time_joined'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online, Marketplace',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        }
      ],
      title: {
        text: 12,
        textStyle: {
          color: '#333333',
          fontSize: '69',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 150,
        radius: [90, 130],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      colorsRing: ['#FFCB9F', '#FFE78F', '#A1FFD1', '#C5DBFF'],
      colorsLine: ['#00D79E', '#1DA1F3'],
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 },
          { 'date': '12', 'sales': 30 },
          { 'date': '13', 'sales': 40 }
        ]
      },
      histogramSettings: {
        metrics: ['confirmed', 'cancelled'],
        stack: { 'sales': ['confirmed', 'cancelled'] }
      },
      colorsHisto: ['#00D79E', '#F5295A'],
      dataUpcomingAppointment: {
        columns: ['date', 'confirmed', 'cancelled'],
        rows: [{ 'date': 'Mon 10', 'confirmed': 2, 'cancelled': 2 },
          { 'date': 'Tue 11', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Wed 12', 'confirmed': 4, 'cancelled': 0 },
          { 'date': 'Thr 13', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Fri 14', 'confirmed': 6, 'cancelled': 2 },
          { 'date': 'Sat 15', 'confirmed': 7, 'cancelled': 0 },
          { 'date': 'Sun 16', 'confirmed': 3, 'cancelled': 2 }
        ]
      },
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      page: 1,
      total_page: 10,
      dialog: false
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },

    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    editCashback () {
      this.dialog = true
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
